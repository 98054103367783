import axios from 'axios'
import Cookies from 'js-cookie'

const apiClient = axios.create({
  baseURL: `${process.env.GATSBY_MICROSERVICE_API}api/`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 15000,
})

function setAuthHeader(token: string) {
  if (!token) return
  apiClient.defaults.headers.common.authorization = token
}

apiClient.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const status = error.response ? error.response.status : 401
    if (status === 401) {
      Cookies.remove('basicAuthToken')
      if (window) window.location.reload()
    }

    return Promise.reject(error)
  }
)

export default apiClient
export { setAuthHeader }
