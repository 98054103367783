import apiClient from './basicapi'

export interface IBundle {
  uniqueId: string
  name: string
  filename: string
  category: string
}

export interface IOrder {
  id: 0
  firstname: string
  lastname: string
  company: string
  department: string
  streetAndNumberOrPoBox: string
  postcodeAndCity: string
  phone: string
  email: string
  message: string
  timestamp: string
  products: Array<{
    title: string
    amount: 0
  }>
}

export interface INotificationRecipient {
  id: number
  name: string
  email: string
}

export interface IPostNotificationRecipient {
  name?: string
  email: string
}

const apiRoot = 'shoppingcard/admin/'

export function getOrders(): Promise<Array<IOrder>> {
  return apiClient.get(`${apiRoot}Orders`).then((res) => res.data)
}

export function deleteOrder(id: number) {
  return apiClient.delete(`${apiRoot}Orders/${id}`)
}

export function getNotificationRecipients(): Promise<
  Array<INotificationRecipient>
> {
  return apiClient
    .get(`${apiRoot}NotificationRecipients`)
    .then((res) => res.data)
}

export function postNotificationRecipients(
  recipient: IPostNotificationRecipient
) {
  return apiClient.post(`${apiRoot}NotificationRecipients`, recipient)
}

export function putNotificationRecipients(
  id: number,
  recipient: IPostNotificationRecipient
) {
  return apiClient.put(`${apiRoot}NotificationRecipients/${id}`, recipient)
}

export function deleteNotificationRecipients(id: number) {
  return apiClient.delete(`${apiRoot}NotificationRecipients/${id}`)
}
